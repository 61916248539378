<template>
  <div id="pageContent">
    <div style="padding: 10px">
      <el-button type="primary" size="mini" @click="addEvent"
        >添加</el-button
      >
    </div>
    <div style="margin-left: 10px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>

        <el-table-column prop="id" label="Id" width="50" align="center">
        </el-table-column>

        <el-table-column prop="theme_name" label="主题名称" align="left">
        </el-table-column>

        <el-table-column prop="goods_type_arr" label="分类" align="left">
        </el-table-column>

        <el-table-column prop="theme_name_en" label="主题名称英文" align="left">
        </el-table-column>

        <el-table-column prop="banner_img" label="海报图（点击查看大图）">
          <template v-slot="scope">
            <el-image style="width: 50px; height: 50px;margin: 5px;" :src="item" v-for="(item,index) in scope.row.BigImageArr" :key="index" alt="" :preview-src-list="scope.row.BigImageArr"></el-image>
          </template>
        </el-table-column>

        <el-table-column prop = "created_at" label="创建时间">
          <template v-slot="{row}">{{ row.created_at ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') : "" }}</template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="220">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="danger" size="mini" @click="editInfo(scope.row)"
                >编辑</el-button
              >
              <el-button type="infor" size="mini" @click="toInfo(scope.row)"
                >查看</el-button
              >
              <el-button type="warning" size="mini" @click="delDis(scope.row)"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>

    <!-- 抽屉效果 -->
    <el-drawer
      size="50%"
      title="活动管理"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="border: 1px solid #ccc;border-right: none;border-left: none;padding: 5px;width: 95%;margin: 0 auto;">
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item label="主题名称">
            <el-input v-model="form.theme_name"></el-input>
          </el-form-item>

          <el-form-item label="主题名称英文">
            <el-input v-model="form.theme_name_en"></el-input>
          </el-form-item>

          <el-form-item label="绑定的商品分类">
            <el-tag
              v-for="keyWordTag in dynamicKeyWordTags"
              :key="keyWordTag"
              size="mini"
              closable
              :disable-transitions="false"
              @close="handleKeyWordTagClose(keyWordTag)"
            >
              {{ keyWordTag }}
            </el-tag>
            <el-input
              v-if="inputKeyWordVisible"
              ref="saveKeyWordTagInput"
              v-model="dynamicKeyWordTagsValue"
              placeholder="请输入搜索关键字"
              size="mini"
              class="input-new-tag"
              clearable
              @keyup.enter.native="handleKeyWordInputConfirm"
              @blur="handleKeyWordInputConfirm"
            />
            <el-button v-else class="button-new-tag" size="small" icon="" @click="showKeyWordInput">
              点击输入，按[enter]确定
            </el-button>
            <el-button
              class="button-new-tag-close"
              type="primary"
              size="mini"
              icon=""
              plain
              @click="handleKeyWordInputClear"
            >清空输入
            </el-button>
          </el-form-item>

          <el-form-item label="是否显示banner海报">
            <el-radio v-model="form.is_show_banner" label="0">不显示</el-radio>
            <el-radio v-model="form.is_show_banner" label="1">显示</el-radio>
          </el-form-item>

          <el-form-item label="baner海报图">
              <el-upload class="upload-demo"
                ref="uploadImgEl"
                :action="uploadImgConfig.uploadMultipartUrl"
                :on-preview="handlePreview"
                list-type="picture-card"
                :multiple="false"
                :auto-upload="false"
                :file-list="uploadBannerImgList"
                :on-exceed="handleExceed"
                :limit="uploadImgConfig.uploadLimit"
                :on-change="handleFileChange"
                :before-remove="handleImgBeforeRemove"
                >
                <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
              </el-upload>
              <!--            图片放大预览-->
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                  <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="conscelSubmit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import common from "@/common/mixins/common.js";
import themezoneApi from "@/api/admin/themezone";

export default {
  mixins: [common],
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      drawer: false,
      direction: "rtl",
      form: {
        id:0,
        theme_name: "",
        theme_name_en:"",
        banner_img:"",
        is_show_banner:0,
        uploadBannerImgList:[],
        goods_type_arr:[],
      },
      // 商品类别输入框
      dynamicKeyWordTags: [],
      dynamicKeyWordTagsValue: '',
      inputKeyWordVisible: false,
      formId: 0,
      // 图片上传处理
      loading: false, // 加载loading
      acceptImgType:["bmp","jpg","jpeg","png","webp"],//图片格式限制
      uploadImgConfig: { // 图片上传配置 - 若不配置则使用quillEditor默认方式，即base64方式
        uploadUrl: this.$root.DownUrl+'/a1/upload/file', // 图片上传地址
        uploadMultipartUrl: this.$root.DownUrl+'/a1/upload/multFile', // 多图片上传地址
        maxSize: 20, // 图片上传大小限制，默认不超过20M
        name: 'file', // 图片上传字段
        uploadLimit: 1, //图片上传限制数量
      },
      // 图片上传POST地址
      headerObj: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      // 预览图片
      dialogImageUrl: '',
      dialogVisible: false,
      uploadBannerImgList:[], //上传成功的图片
      UploadReadyBannerImgList:[],//待上传的图片
      
    };
  },
  watch:{
    
    
  },
  created() {
    this.getListData();
  },
  methods: {
    // 搜索输入框@connor
    handleKeyWordTagClose(tag) {
      this.dynamicKeyWordTags.splice(this.dynamicKeyWordTags.indexOf(tag), 1)
      this.form.goods_type_arr.splice(this.form.goods_type_arr.indexOf(tag), 1)
    },
    // 清空输入框
    handleKeyWordInputClear() {
      this.form.goods_type_arr = []
      this.dynamicKeyWordTags = []
    },
    // 显示输入框
    showKeyWordInput() {
      this.inputKeyWordVisible = true
          this.$nextTick(_ => {
          this.$refs.saveKeyWordTagInput.$refs.input.focus()
      })
    },
    // 输入框确认
    handleKeyWordInputConfirm() {
      var inputValue = this.dynamicKeyWordTagsValue
      var indexKey = this.dynamicKeyWordTags.indexOf(inputValue)
      if (inputValue && (indexKey === -1)) {
        this.dynamicKeyWordTags.push(inputValue)
        this.form.goods_type_arr.push(inputValue)
      }
      this.inputKeyWordVisible = false
      this.dynamicKeyWordTagsValue = ''
    },
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getListData();
    },
    //查看活动详情的商品列表
    toInfo(row) {
      this.$router.push({
        path: "themezoneGoodsList",
        query: {
          id: row.id,
        },
      });
    },
    delDis(row) {
      this.$confirm("你确定要删除这个活动吗？")
        .then((_) => {
          themezoneApi.del(row.id).then((res) => {
            this.$message.success("删除成功");
            this.getListData();
          });
        })
        .catch((_) => {
          this.$message.info("取消删除");
        });
    },
    // 取消操作
    conscelSubmit(){
      this.drawer = false;
      this.clearImages() //清空旧数据
      this.clearTypeInput()
    },
    //提交
    onSubmit() {
      this.form.is_show_banner = parseInt(this.form.is_show_banner)
      if (this.formId > 0) {
        this.form.id = this.formId
        themezoneApi.edit(this.form).then((res) => {
          this.$message.success("修改成功");
          this.getListData();
          this.drawer = false;
        }).catch((err)=>{
          this.drawer = false;
          this.$message.error(err.response.data.message);
        });
      } else if (this.formId == 0) {
        themezoneApi.add(this.form).then((res) => {
          this.$message.success("添加成功");
          this.getListData();
          this.drawer = false;
        }).catch((err)=>{
          this.getListData();
          this.drawer = false;
          this.$alert(err.response.data.message, '提示', {
            confirmButtonText: '确定',
          });
        });
      }
    },
    // 清空分类数据
    clearTypeInput(){
      this.form.goods_type_arr = []
      this.handleKeyWordInputClear()
    },
    //修改详情
    editInfo(row) {
      this.clearTypeInput()
      this.clearImages() //清空旧数据
      this.form.theme_name = row.theme_name;
      this.form.theme_name_en = row.theme_name_en;
      this.formId = row.id;
      this.drawer = true;
      this.form.is_show_banner = row.is_show_banner?row.is_show_banner.toString():"0"
        // 判断是否存在图片
        if (row.banner_img !=="") {
            const ImgNameArr = row.banner_img.split('/')
            setTimeout(() => {
              this.uploadBannerImgList.push({
                name:ImgNameArr[ImgNameArr.length - 1],
                url:row.banner_img
              })
            }, 100);
            this.form.uploadBannerImgList.push(row.banner_img)
          }
          // 判断是否有商品分类
          if(row.goods_type_arr !==""){
            var type_arr = []
            if (row.goods_type_arr.indexOf(",")>=0) {
              type_arr = row.goods_type_arr.split(",")
            } else {
              type_arr = [row.goods_type_arr]
            }
            this.dynamicKeyWordTags = JSON.parse(JSON.stringify(type_arr))
            this.form.goods_type_arr = type_arr
          }
    },
    //添加活动触发
    addEvent() {
      this.form = {
        id:0,
        theme_name: "",
        theme_name_en:"",
        banner_img:"",
        is_show_banner:0,
        uploadBannerImgList:[],
        goods_type_arr:[],
      };
      this.formId = 0;
      this.drawer = true;
      this.clearImages() //清空旧数据
      this.clearTypeInput()
    },
    //抽屉关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //获取到活动数据
    getListData() {
      this.tableData = [];
      this.pagination.total = 0;
      var params = {
        pageSize: this.pagination.pageSize,
        page: this.pagination.page,
      }
      themezoneApi.get(params).then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          // 设置图片预览
          if (this.tableData.length >0) {
            for (const key in this.tableData) {
              if (typeof(this.tableData[key].BigImageArr) === "undefined") {
                this.tableData[key].BigImageArr = []
              }
             if(this.tableData[key].banner_img){
              this.tableData[key].BigImageArr.push(this.tableData[key].banner_img)
             }
            }
          }
          this.reloadPaginate++;
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓图片····相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
    handleExceed(files, fileList) {
      this.$message.warning(`限制选择`+this.uploadImgConfig.uploadLimit+`个文件，本次选择了 `+files.length+`个文件`)
    },
    //清空图片数据
    clearImages(){
      this.uploadBannerImgList = [] 
      this.form.uploadBannerImgList = []
    },
    // 过滤重复
    filterRepetition(arr) {
      let arr1 = []; //存id
      let newArr = []; //存新数组
      for (let i in arr) {
        if (arr1.indexOf(arr[i].name) == -1) {
          arr1.push(arr[i].name);
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 修改 存放要上传的文件列表
    handleFileChange(file, fileList) {
      let isNotAlowUpload = false //不允许上传
      let arr = this.filterRepetition(fileList);
      if (arr.length !== fileList.length) {
        isNotAlowUpload = true
        this.$message("上传重复文件，已过滤重复文件");
      }
      //文件判断
      for (const key in arr) {
        if (typeof(arr[key].raw)!=="undefined") {
          // 判断是否超出限制大小10M
        if(arr[key].size / 1024 / 1024 > 5){
            isNotAlowUpload = true
            this.$message.error("每个上传图像的大小不应超过5M");
          } 
          // 判断格式是否正确
          let typeExt = arr[key].raw.type
          let typeExtBool = false
          for (const key in this.acceptImgType) {
            if (typeExt.indexOf(this.acceptImgType[key])>-1) {
              typeExtBool = true
            }
          }
          if (typeExtBool===false) {
            isNotAlowUpload = true
            this.$message.error("请上传以下格式的图像:"+this.acceptImgType.join("/"));
          }
        } else {
          continue //如果不是新选择的文件，跳过检验
        }
      }

      // 不超过限制才上传
      if (isNotAlowUpload === false) {
        this.UploadReadyBannerImgList = arr;
        this.debounce(this.submitUpload, 500)
      } else {
        // 清空不符合缓存
        let uploadImgEl = this.$refs.uploadImgEl.uploadFiles
        for (const key in uploadImgEl) {
          if (uploadImgEl[key].status ==="ready") {
            this.$refs.uploadImgEl.uploadFiles.splice(key,1)
          }
        }
      }


    },
    // element上传多个文件时，会把每个文件做个单独请求
    // 这里的方法是请求最后一次
    debounce(fn, waits) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        fn.apply(this, arguments); // 把参数传进去
      }, waits);
    },

    // 确定
    async submitUpload() {
      if (this.UploadReadyBannerImgList.length === 0) {
        this.$message.error("请上传文件");
        return;
      }

      let formData = new FormData(); //  用FormData存放上传文件
      this.UploadReadyBannerImgList.forEach((file) => {
        formData.append("file", file.raw); // file.raw
      });

      // 确定上传 把在上传列表里的文件 合并到formData里面传给后台
      const loading = this.$loading({
        lock: true,
        text: 'Uploading, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // 使用多图片上传接口
      let res = await themezoneApi.uploadApi(this.uploadImgConfig.uploadMultipartUrl,formData,this.headerObj.Authorization);
      if (res.data.error === 0) {
        let data = res.data.data
        for (const key in data) {
          const ImgNameArr = data[key].split('/')
          this.uploadBannerImgList.push({
            name:ImgNameArr[ImgNameArr.length - 1],
            url:data[key]
          })
          console.log(this.form)
          this.form.uploadBannerImgList.push(data[key])
        }
      }
      loading.close()
    },

    // 预览图片
    handlePreview(file) {
        this.dialogImageUrl=file.url;
        this.dialogVisible=true;
    },

    /**
     * 删除图片
     */
    handleDeleteImg(postData) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      themezoneApi.deleteIconImg(postData).then((res) => {
          const error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getListData()
        loading.close()
      })
    },

    /* 删除未提交表单的图片 */
    handleDeleteTestImg(imgArr) {
      const loading = this.$loading({
        lock: true,
        text: 'Deleting, please wait',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      themezoneApi.DeleteIconTestImg({ img_arr: imgArr }).then((res) => {
          const  error  = res.data.error
          if (error === 0) {
          this.$message({
              type: 'success',
              message: 'Successfully deleted!'
          })
          } else {
          this.$message({
              type: 'info',
              message: 'undelete'
          })
          }
      }).finally(()=>{
        this.getListData()
        loading.close()
      })
    },

    /* 删除表单的图片 */
    handleDeleteFormImg(file_url){
      for (const key in this.form.uploadBannerImgList) {
        if(this.form.uploadBannerImgList[key] === file_url){
          this.form.uploadBannerImgList.splice(key, 1) // 移除表单图片
        }
      }
    },


    /**
     * 删除图片之前确认
     */
    handleImgBeforeRemove(file, fileList) {
      const bv = false // 阻止直接移除，采用手动移除
      if (file.url.indexOf('/uploads/test/') > -1) {
        var delArr = []
        for (let index = 0; index < this.uploadBannerImgList.length; index++) {
          if (this.uploadBannerImgList[index].url === file.url) {
            this.uploadBannerImgList.splice(index, 1) // 移除展示的图片
            delArr.push({ imgSrc: file.url })
          }

        }
        if (delArr.length > 0) {
          this.handleDeleteTestImg(delArr) // 删除test路径的图片
        }
      } else {
        let tipText = "此操作将永久删除该文件, 是否继续?"
        this.$confirm(tipText, 'prompt', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then((res) => {
            // 移除展示的数组
            for (let index = 0; index < this.uploadBannerImgList.length; index++) {
              if (this.uploadBannerImgList[index].url === file.url) {
                this.uploadBannerImgList.splice(index, 1) // 移除展示的图片
              }
            }
            // 删除图片
            var delArr = []
            delArr.push({imgSrc:file.url})
            this.handleDeleteImg({img_arr: delArr})
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'undelete'
            })
          })
      }
      // 移除表单的数据
      this.handleDeleteFormImg(file.url)
      // 阻止删除
      return bv
    }, 
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑图片····相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/
  },
};
</script>

<style>
.footer {
  margin: 20px 0 0 10px;
}
</style>
