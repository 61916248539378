import axios from "axios";

export default {
  get: (page, pagesize) => {
    const params = {
      page: page,
      pagesize: pagesize,
    };
    return axios.get("/a1/theme", { params: params });
  },
  add: (params) => {
    return axios.post("/a1/theme", params);
  },
  edit: (params) => {
    return axios.put("/a1/theme", params);
  },
  del: (id) => {
    return axios.delete("/a1/theme/"+id);
  },
  moveTestImg:(params)=>{
    return axios.put("/a1/theme/moveTestImg", params);
  },
  DeleteIconTestImg:(params)=>{
    return axios.put("/a1/theme/deleteIconTestImg", params);
  },
  deleteIconImg:(params)=>{
    return axios.put("/a1/theme/deleteIconImg", params);
  },
  uploadApi:(url,data,token) =>{
    return axios.post(url,data,{headers: {'Authorization':token }});
  },
  getDetail: (id) => {
    return axios.get(`/a1/theme/${id}`);
  },
};
